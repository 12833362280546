<template>
    <div>
        <div class="multi-ctrls">
            <a-button type="danger" :disabled="selectedRowKeys.length===0" @click="mutilDel">
                删除
            </a-button>
            <div class="clear"></div>
        </div>

        <a-table 
            :columns="columns" 
            :data-source="list" 
            :rowKey="record=>record.LOG_ID" 
            :bordered="false"
            :pagination="pageSetting"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            @change="tableChange"
            >
            <!-- <template slot="NAMEZH" slot-scope="text,record">
                <router-link :to="{path:'/datas_data',query:{cubeId:record.CUBEID}}" v-if="record.PARENTNAMEZH">
                    {{record.PARENTNAMEZH+'-'+text}}
                </router-link>
                <router-link :to="{path:'/datas_data',query:{cubeId:record.CUBEID}}" v-else>
                    {{text}}
                </router-link>
            </template> -->
            <template slot="actions" slot-scope="text,record">
                <a-button type="link" @click="del(record.LOG_ID)">删除</a-button>
            </template>
        </a-table>
    </div>
</template>

<script>
import { messageTips } from '../../until/tools';
const columns = [
	{
		title: '数据库',
		dataIndex: 'nameZH',
		key: 'nameZH',
		scopedSlots: { customRender: 'nameZH' },
    },
    {
		title: '浏览日期',
		dataIndex: 'addTime',
		key: 'addTime',
	},
    {
		title: '操作',
		dataIndex: 'actions',
		key: 'actions',
		scopedSlots: { customRender: 'actions' },
        align:'center'
	},
];
export default {
    name:'History',
    data() {
        return {
            columns,
            list:[],
            page:{},
            selectedRowKeys:[]
        }
    },
    computed:{
        pageSetting(){
			let pageSize=0;
			if(this.page.allSize) {
				pageSize = this.page.allSize;
			}
			return {
				showQuickJumper:true,
				hideOnSinglePage:true,
				showTotal:()=>`共${pageSize}条`,
				total:pageSize,
			}
		},
    },
    created() {
        this.getList();
    },
    methods:{
        getList(page) {
            this.$http.getBrowseHistory({currentPage:page?page:1}).then(res=>{
                if(res) {
                    if(res.list) {
                        this.list = res.list;
                        this.page = res.page;
                    }
                }
            });
        },
        tableChange(e) {
			this.getList(e.current,this.keyword);
		},
        del(ids) {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText:'继续',
                cancelText:'取消',
                centered:true,
                onOk() {
                    that.confirmDel(ids);
                },
                onCancel() {},
            });  
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        mutilDel() {
            this.del(this.selectedRowKeys.join(','));
        },
        confirmDel(ids) {
            this.$http.user_del_history({idStr:ids}).then(res=>{
                if(res) {
                    if(res.success) {
                        messageTips(res.message,3);
                        this.getList(this.page.currentPage);
                    }else {
                        messageTips(res.message,1);
                    }
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .multi-ctrls {
        padding:20px 0;
        button {
            float:right;
        }
    }
</style>